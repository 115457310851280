$.lui("comp",".comp_chart",function (selector) {
    let chart_elm = selector.find("[data-chart]");

    if(chart_elm.length) {
        if(chart_elm.data("lib-load") === true) {
            let years = chart_elm.data("chart");
            let series = [0];
            let height = 400;
            let anim_delay = 150;
            let anim_duration = 300;

            let year_elm = selector.find(".elm_year");

            let details = selector.find(".wrp_comp_foot").find(".elm_item");

            years.map(function (year, index) {
                let y = 55 + (height - 55) * (1 - (index + 0.5) / years.length) + (0.5 - index % 2) * 15;
                series.push(Math.floor(height-y));
            });

            series.push(height-years.length);

            $.importStyle(cdnjs.chartist_css);
            $.importScript(cdnjs.chartist,function () {
                $.importScript(cdnjs.countup,function () {
                    let chart = new Chartist.Line(chart_elm.get(0),{
                        series: [series]
                    }, {
                        width: 1200,
                        height: height,
                        showArea: true,
                        showLabel: false,
                        fullWidth: true,
                        axisX: {
                            showGrid: false,
                            showLabel: false,
                            offset: 0
                        },
                        axisY: {
                            showGrid: false,
                            showLabel: false,
                            offset: 0
                        },
                        chartPadding: {
                            bottom: 40
                        },
                        low: 0
                    });
                    chart.on("draw",function (data) {
                        if(data.type === 'point') {
                            if(data.index === 0 || data.index === series.length -1) {
                                let hidden = new Chartist.Svg('path', {
                                    style: 'fill-opacity: 0'
                                });
                                data.element.replace(hidden);
                            }
                            else {
                                let group = new Chartist.Svg("g",{
                                    "data-year": years[data.index-1],
                                },'ct-bullet');
                                let circle1 = new Chartist.Svg("circle", {
                                    cx: data.x,
                                    cy: data.y,
                                    r: 14,
                                }, 'ct-outer');
                                circle1.animate({
                                    cy: {
                                        begin: anim_delay * data.index-1,
                                        dur: anim_duration,
                                        from: 30 * (series.length - data.index-1),
                                        to: data.y
                                    },
                                    opacity: {
                                        begin: anim_delay * data.index-1,
                                        dur: anim_duration,
                                        from: 0,
                                        to: 1
                                    }
                                });
                                let circle2 = new Chartist.Svg("circle", {
                                    cx: data.x,
                                    cy: data.y,
                                    r: 8,
                                }, 'ct-inner');
                                circle2.animate({
                                    cy: {
                                        begin: anim_delay * data.index-1,
                                        dur: anim_duration,
                                        from: 0,
                                        to: data.y
                                    }
                                });
                                let text = new Chartist.Svg("text",{
                                    x: data.x,
                                    y: data.y - 25,
                                },'ct-label').text(years[data.index-1]);
                                let line = new Chartist.Svg("line",{
                                    x1: data.x,
                                    x2: data.x,
                                    y1: data.y + 16,
                                    y2: parseInt(data.axisY.chartRect.y1 - 8)
                                },'ct-dropline');
                                line.animate({
                                    y2: {
                                        begin: anim_duration * data.index-1,
                                        dur: anim_duration,
                                        from: data.y + 16,
                                        to: parseInt(data.axisY.chartRect.y1 - 8)
                                    }
                                });

                                if(data.index === series.length -2) {
                                    setTimeout(function () {
                                        group.addClass("state--active");
                                    },anim_delay*series.length);
                                }

                                group.elem(line.getNode());
                                group.elem(circle1.getNode());
                                group.elem(circle2.getNode());
                                group.elem(text.getNode());
                                data.element.replace(group);
                            }
                        }
                        if(data.type === 'line' || data.type === 'area') {
                            data.element.animate({
                                d: {
                                    begin: 500,
                                    dur: 2000,
                                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                                    to: data.path.clone().stringify(),
                                    easing: Chartist.Svg.Easing.easeOutQuint
                                }
                            });
                        }
                    });

                    chart_elm.on("click","[data-year]",function () {
                        let el = $(this),
                            detail = details.filter("[data-year=\""+el.data("year")+"\"]");

                        chart_elm.find(".ct-bullet").filter(".state--active").removeClass("state--active");
                        el.addClass("state--active");

                        if(detail.hasClass("state--active")) {
                            return;
                        }
                        let counter = new CountUp(year_elm.get(0),parseInt(year_elm.text()),parseInt(el.data('year')),0, 1,{
                            separator: "",
                            useGrouping: false,
                        });
                        if (!counter.error) {
                            counter.start();
                        } else {
                            console.error(counter.error);
                        }

                        details.filter(".state--active").not(detail).removeClass("state--active");
                        detail.addClass("state--active");
                        detail.find("[data-number-anim]").each(function () {
                            let number = $(this);

                            let options = {
                                separator: ' ',
                                decimal: ',',
                                prefix: (number.data("number-anim").prefix === undefined) ? "" : number.data("number-anim").prefix,
                                suffix: (number.data("number-anim").suffix === undefined) ? "" : number.data("number-anim").suffix,
                            };

                            let counter = new CountUp(number.get(0),0,parseInt(number.text().match(/([0-9])+/g).join("")),0, 2,options);
                            if (!counter.error) {
                                counter.start();
                            } else {
                                console.error(counter.error);
                            }
                        });
                    })
                });
            });
        }
    }
});