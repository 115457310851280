$.lui("comp",".comp_scheme",function (selector) {
    selector.on("click", `[data-click="dialogDevices"]`, function(){
        let elm = $(this);
        lui_dialog.open(`
            <div class="part_dialog part_dialog_basic part_dialog_roles size--small">
                <div class="wrp_dialog">
                    <button class="elm_dialog_close icon--close" data-lib-dialog-close=""></button>
                    <div class="wrp_dialog_head">
                        <div class="part_text">
                            <h2 class="part_ui_heading">${elm.find(".icon").next().text()}</h2>
                        </div>
                    </div>
                    <div class="wrp_dialog_body">
                        <div class="part_ui_wsw"><p>${elm.nextAll(".elm_item_tooltip").text()}</p></div>
                    </div>
                </div>
            </div>
        `);
    });
});