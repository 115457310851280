jQuery.lui = function(n,s,callback) {
    (function fn(name, selector) {
        let fns;
        let name_selector;

        if (typeof selector === "string") {
            name_selector = selector.split(" ").splice(-1)[0];

            if ($(selector).length) {
                fns = callback($(selector),{})
            }
        } else {
            if (selector.length) {
                fns = callback(selector,{})
            }
        }

        if (name.indexOf("lib") !== -1) {
            if (typeof window.lui["lib"][name] === "undefined") {
                window.lui["lib"][name] = fn;
                window.lui["lib"][name].selector = selector;
            }
        } else {
            if (typeof window.lui[name][name_selector] === "undefined") {
                window.lui[name][name_selector] = fn;
                window.lui[name][name_selector].selector = selector;
            }

            if (typeof fns !== "undefined") {
                window.lui[name][name_selector].fns = fns;
            }
        }
    })(n,s);
};