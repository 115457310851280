$.fn.beforeImgLoad = function (callback) {
    let img = $(this);

    let wait = setInterval(function(){
        let w = img.width(),
            h = img.height();

        if (!img.hasClass("lazyload")) {
            if(w && h){
                done(w, h);
            }
        }
    }, 0);

    let onLoad;
    img.on('load', onLoad = function(){
        done(img.width(), img.height());
    });


    let isDone = false;
    function done(){
        if(isDone){
            return;
        }
        isDone = true;

        clearInterval(wait);
        img.off('load', onLoad);

        callback.apply(this, arguments);
    }
};