$.lui("comp",".comp_text_head",function (selector) {
    let nav = selector.find(".elm_nav");

    if(nav.length) {
        let inner = nav.find("ul"),
            inner_col = inner.find("li"),
            inner_end = fn_col_width(inner_col.length);

        fn_nav_scroll();
        inner.on("scroll",fn_nav_scroll);
        win.on("resize",fn_nav_scroll());

        inner.scrollLeft(fn_col_width(nav.find(".state--active").parent().index()));

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });
            return width;
        }

        function fn_nav_scroll() {
            nav.removeClass("is--state-end is--state-start");
            if (parseInt(inner.scrollLeft()+inner.width()) === inner_end) {
                nav.addClass("is--state-end");
            }
            if (inner.scrollLeft() < 48) {
                nav.addClass("is--state-start");
            }
        }
    }
});