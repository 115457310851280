$.lui("part",".part_item_faq",function (selector) {
    selector.on("click","[data-click]",function (e) {
        let el = $(this).closest(selector),
            body = el.find(".wrp_item_body");

        if(el.hasClass("state--active")) {
            body.stop().slideUp(function () {
                el.removeClass("state--active");
            })
        }
        else {
            el.addClass("state--expanding");
            body.stop().slideDown(function () {
                el.addClass("state--active").removeClass("state--expanding");
            })
        }
    })
});