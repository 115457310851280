$.fn.lib_recaptcha = function (attr) {
    let elm = $(this);
    if(elm.length) {
        $.importScript(cdnjs.recaptcha.replace("APIKEY",attr), function(){
            grecaptcha.ready(function() {
                grecaptcha.execute(attr, {action: 'form'}).then(function(token) {
                    elm.find(`[name="g-token"]`).val(token);
                });
            });
        })
    }
};