jQuery.importScript = function( url, callback ) {
    if ($.importScript.used.indexOf(url) === -1) {
        $.importScript.used.push(url);
        return jQuery.ajax({
            dataType: "script",
            cache: true,
            url: url,
            async: false,
            success: function() {
                $.importScript.loaded.push(url);
                if (callback) {
                    callback();
                }
            },
            error: function(){
                $.importScript.used.splice($.importScript.used.indexOf(url), 1);
                return false;
            }
        });
    } else {
        if (callback) {
            let interval = setInterval(function(){
                if ($.importScript.used.indexOf(url) === -1) {
                    clearInterval(interval);
                    return false;
                }
                if ($.importScript.loaded.indexOf(url) !== -1) {
                    clearInterval(interval);
                    callback();
                }
            },1);
        }
    }

};

jQuery.importScript.used = [];
jQuery.importScript.loaded = [];
