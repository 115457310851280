$.lui("part",".part_ui_alert",function (selector) {
    selector.on("click", `[data-click="close"]`, function(e){
        e.preventDefault();
        let elm = $(this);
        elm.closest(".part_ui_alert").fadeOut(300);
        setTimeout(function() {
            elm.closest(".part_ui_alert").remove();
        },300);
    });

    selector.each(function () {
        let elm = $(this);
        setTimeout(function () {
            elm.fadeOut(300,function () {
                elm.remove();
            });
        },3000);
    })
});