let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "chartist": "https://cdn.jsdelivr.net/npm/chartist@0.11.0/dist/chartist.min.js",
    "chartist_labels": "https://cdn.jsdelivr.net/npm/chartist-plugin-pointlabels@0.0.6/dist/chartist-plugin-pointlabels.min.js",
    "chartist_css": "https://cdn.jsdelivr.net/npm/chartist@0.11.0/dist/chartist.min.css",
    "countup": "https://cdn.jsdelivr.net/npm/countup.js@1.9.3/dist/countUp.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render=APIKEY"
};