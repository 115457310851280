$.lui("comp",".comp_visual",function (selector) {
    selector.on("click","[data-visual-scroll]",function () {
        selector.next("section, div").anchor_anim(500);
    });

    let slider = selector.find("[data-slider]");
    if(slider.find(".elm_item").length > 1){
        let options = slider.data("slider"),
            videos = slider.find("video");

        $.lib_flickity(function(){
            slider.flickity({
                groupCells: false,
                cellAlign: "left",
                setGallerySize: false,
                autoPlay: options["autoplay"],
                pageDots: true,
                wrapAround: true,
                contain: true,
                pauseAutoPlayOnHover: true,
                prevNextButtons: (typeof options["prevNextButtons"] !== "undefined") ? options["prevNextButtons"] : false,
            });

            slider.on("change.flickity",function(event, index){
                videos.each(function () {
                    $(this).get(0).pause();
                });
                let video = $(slider.data("flickity").cells[index].element).find("video");
                if(video.length) {
                    video.get(0).play();
                }
            });
        })
    }
});